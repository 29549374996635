import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
 import axios from 'axios';
 import { base_url } from '../constants';

export class Allevent extends Component {
    state = {
        events: []
    }
       componentDidMount() {
        fetch(`${base_url}/event/fetchallevents`)
        .then((response) => response.json())
        .then(eventList => {
            this.setState({ events: eventList });
        });
    }
    
    deleteExercise(id) {
        axios.delete(`${base_url}/event/`+id)
          .then(response => { 
               window.location='/allevent';
              console.log(response.data)}
              
              )
        
          .catch((error) => {
            console.log(error);
          })
      }
    
  logout = () => {
      localStorage.removeItem("admintoken");
      localStorage.clear();
      this.setState({ redirect: true });
      swal({
          title: "Done!",
          text: "Logout Success",
          icon: "success",
          timer: 1000,
          button: false
        })
  
        window.location='/';
  
  }
  formatDate = (date) => {
    let d = new Date(date);
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
}
   
    render() {
        return (
            <div class="main-content">
                    <div className="panel-body">
                    <div className="content-area">
                        <div className="sub-bar">
                        <div className="sub-title">
                            <h4>Dashboard:</h4>
                            <span>Welcome To web Admin Panel!</span>
                        </div>
                        <ul className="bread-crumb">
                        <li><button class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                            <li>Event</li>
                        </ul>
                        </div>
                        <div className="gap no-gap">
                        <div className="inner-bg">
                            <div className="element-title">
                            <div className="col-md-12">
                                <div class="row">
                                    <div class="col-lg-6">
                                    <h4>Event List</h4>
                                    </div>
                                    <div class="col-lg-6 text-right">
                                       <Link to="/event"><button  className="btn btn-primary" type="submit">Add Event</button></Link>
                                     </div>
                                </div>
                            </div>
                         </div>
                            <table className="cart-table table table-responsive">
                            <thead>
                                <tr>
                                <th>Event Name</th>
                                <th>Category</th>
                                <th>Price</th>
                                <th>Discount Price</th>
                                <th>Time</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Images</th>
                                <th>Edit</th>
                                <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.events.map((e) => (
                                <tr>
                                <td>{e.eventtitle}</td>
                                <td>{e.category}</td>
                                <td>£{e.price}</td>
                                <td>£{e.discountprice}</td>
                                <td>{e.description}</td>
                                <td>{e.time}</td>
                                <td>{this.formatDate(e.eventdate)}</td>
                                <td><img style={{ width: '100px', height: '100px' }} src={`${base_url}/eventImages/`+ e.images} /></td>
                                <td><button><Link to={"/editevent/"+ e._id}><i className="icon-pencil" /></Link></button></td>
                                <td><button  onClick={() => { this.deleteExercise(e._id) }}><i class="icon-trash"></i></button> </td>
                                </tr>
     ))}
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    </div>
                
            </div>
        )
    }
}

export default Allevent
