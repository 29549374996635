import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import { base_url } from '../constants';


export class Allproduct extends Component {
    constructor() {
        super();

      

        this.state = {
            
            products: []
        };
    }

  componentDidMount() {
        fetch(`${base_url}/product/fetchall`)
        .then((response) => response.json())
        .then(productList => {
            this.setState({ products: productList });
        });
    }
    deleteExercise(id) {
      axios.delete(`${base_url}/product/`+id)
        .then(response => { 
             window.location='/allproduct';
            console.log(response.data)}
            
            )
      
        .catch((error) => {
          console.log(error);
        })
    }
  
logout = () => {
    localStorage.removeItem("admintoken");
    localStorage.clear();
    this.setState({ redirect: true });
    swal({
        title: "Done!",
        text: "Logout Success",
        icon: "success",
        timer: 1000,
        button: false
      })

      window.location='/';

}
    
    render() {
        return (
            <div class="main-content">
                    <div className="panel-body">
                    <div className="content-area">
                        <div className="sub-bar">
                        <div className="sub-title">
                            <h4>Dashboard:</h4>
                            <span>Welcome To web Admin Panel!</span>
                        </div>
                        <ul className="bread-crumb">
                         <li><button   class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                            <li>Dashbord</li>
                        </ul>
                        </div>
                        <div className="gap no-gap">
                        <div className="inner-bg">
                            <div className="element-title">
                            <div className="col-md-12">
                                <div class="row">
                                    <div class="col-lg-6">
                                    <h4>Product List</h4>
                                    </div>
                                    <div class="col-lg-6 text-right">
                                       <Link to="/product"><button  className="btn btn-primary" type="submit">Add Product</button></Link>
                                     </div>
                                </div>
                            </div>
                         </div>
                            <table className="cart-table table table-responsive">
                            <thead>
                                <tr>   
                                <th>Product Name</th>
                                <th>Category</th>
                                <th>Price</th>
                                <th>Discount Price</th>
                                <th>Description</th>
                                <th>Images</th>
                                <th>Edit</th>
                                <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.products.map((p) => (
                                <tr> 
                                <td>{p.producttitle}</td>
                                <td>{p.category}</td>
                                <td>£{p.price}</td>
                                <td>£{p.discountprice}</td>
                                <td>{p.description}</td>
                                <td><img style={{ width: '100px', height: '100px' }} src={`${base_url}/productImages/`+ p.images} /></td>
                                <td><button><Link to={"/edit/"+ p._id}><i className="icon-pencil" /></Link></button></td>
                                <td><button  onClick={() => { this.deleteExercise(p._id) }}><i class="icon-trash"></i></button> </td>
                                </tr>
                        ))}
                          
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
               </div>
                
            </div>
        )
    }
}

export default Allproduct
