import React, { Component } from 'react'
import swal from 'sweetalert';
import { base_url } from '../constants';

export class Allorder extends Component {
    state = {
        foundOrder: []
    }
    componentDidMount() {
        fetch(`${base_url}/api/fetchdetails`)
        .then((response) => response.json())
        .then(orderList => {
            this.setState({ foundOrder: orderList });
        });

    }

logout = () => {
    localStorage.removeItem("admintoken");
    localStorage.clear();
    this.setState({ redirect: true });
    swal({
        title: "Done!",
        text: "Logout Success",
        icon: "success",
        timer: 1000,
        button: false
      })

      window.location='/';

}
formatDate = (date) => {
    let d = new Date(date);
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
}
getOrderTotal = (id) => {
    const singleOrder = this.state.foundOrder.find(order => order._id === id);
    let orderTotal = 0;
    singleOrder.order.forEach(order => {
        orderTotal = orderTotal + (order.price * order.quantity)
    });

    return orderTotal;
}
    render() {
        return (
            <div class="main-content">
            <div className="panel-body">
            <div className="content-area">
                <div className="sub-bar">
                <div className="sub-title">
                    <h4>Allorder:</h4>
                    <span>Welcome To web Admin Panel!</span>
                </div>
                <ul className="bread-crumb">
                 <li><button   class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                    <li>Allorder</li>
                </ul>
                </div>
                <div className="gap no-gap">
                <div className="inner-bg">
                    <div className="element-title">
                    <div className="col-md-12">
                        <div class="row">
                            <div class="col-lg-6">
                            <h4>Product Order List</h4>
                            </div>
            
                        </div>
                    </div>
                 </div>
                    <table className="cart-table table table-responsive">
                    <thead>
                        <tr>
                      
                        <th>Order Id</th>
                        <th>orderDate</th>
                        <th>User Name</th>
                       
                        <th>paymentStatus</th>
                        <th>Product Details</th>
                        <th>Address Details</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.foundOrder.map((order) => (
  
                        <tr>
                             <td>{order._id.toLocaleUpperCase()}</td>
                             <td>{this.formatDate(order.orderDate)}</td>
                             <td>{order.username}</td>
                             {/* <td>{order.isOrderCompleted}</td> */}
                             <td>{order.paymentStatus}</td>
                         
                             <td> 
                                {order.order.map(item => (
                                                <div key={item._id} style={{display: 'flex', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #cecece'}}>
                                                       <div className="ImageContainer">
                                                        <img style={{maxWidth: '100%', maxHeight: '100%'}}  src={`${base_url}/productImages/`+ item.image}/>
                                        </div>
                                                 
                                        <div className="col-lg-4">
                                                <h4><span>{item.name}</span></h4>
                                          <div>
                                          <h5>£{item.price }</h5>
                                          <p>Qty:{item.quantity} </p>
                                                </div>
                                        <p>Total £{this.getOrderTotal(order._id)}</p>
                                             </div>
                                           </div>
                                                
                                            ))}
                                           
                                            </td>
                                              
                                   
                         
                             
                              <td>
                               
                              {order.alladdress.map(add => (
                              <div className="col-lg-12 productdd">
                                  
                                     
                                          <lable>Name-<b>{add.fullName}</b><br></br>
                                          Pincode-{add.pinCode}<br></br>
                                          State-{add.state}<br></br>
                                          City-{add.cityDistrictTown}<br></br>
                                          Address-{add.address}<br></br>
                                          Landmark-{add.landmark}<br></br>
                                          Locality-{add.locality}<br></br>
                                          Alt Number-{add.alternatePhoneNumber}
                                          </lable>
                                        
                            
                                           
                             </div>
                              ))}</td>                     
                            </tr>
                    
                        )
                        )}
           
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        
    </div>
      
        );
    }
}

export default Allorder;
