import React, { Component } from 'react'
import GalleryUpload from '../utils/GalleryUpload';
import swal from 'sweetalert';
import axios from 'axios';
import { base_url } from '../constants';

export class Editgallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
             images: '',
             category:null
         
        };
      }
      componentDidMount= async () => {       
        let galleryId = this.props.match.params.id; 
        console.log(galleryId);
        fetch(`${base_url}/gallery/`+ galleryId)
        .then(response => response.json())
        .then(jsonResponse => {
               console.log(jsonResponse);
                    this.setState({
                        category: jsonResponse
                    })
                    console.log(jsonResponse);
                    this.state.images =this.state.category.images;
                    //   alert(this.state.category.categoryname );
                    document.getElementById('images').value = this.state.category.images ; 
                    document.getElementById("img_preview").src=`${base_url}/galleryUpload/`+ this.state.category.images;     
              
        })
        .catch(error => {
            console.log(error);
        });
       //alert(JSON.stringify(this.state.product))
      }
      onSubmit = (event) => {
        event.preventDefault();
        if (!this.state.images) {
            return alert('Please Upload Image')
        }
        const variables = {
         
            images: this.state.images,
            
        }
        console.log(variables);
        let prodId = this.props.match.params.id; 
        axios.post(`${base_url}/gallery/update/` + prodId, variables)
            .then(response => {
                if (response.data.success) {
                    swal({
                        title: "Done!",
                        text: "Success",
                        icon: "success",
                        timer: 1000,
                        button: false,
                        
                      })
                   
                }
                window.location='/allgallery';
            })
    }
      updateFiles = (newImages) => {
        this.setState({ images: newImages })
    }
 
    render() {
        return (
          

<div className="main-content">
<div className="panel-body">
    <div className="content-area">
    <div className="sub-bar">
        <div className="sub-title">
        <h4>Dashboard:</h4>
        <span>Welcome To web Admin Panel!</span>
        </div>
        <ul className="bread-crumb">
        <li><button  class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
        <li>Gallery</li>
        </ul>
    </div>
    <div className="gap no-gap">
        <div className="inner-bg">
        <div className="element-title">
            <h4>Edit Gallery</h4>
            <span>Please fill the form bellow.</span> </div>
        <form onSubmit={this.onSubmit}>
        <div className="add-prod-from">
        <div className="row">
            <div className="col-md-6">
             <label>Gallery Image Upload</label>
             <input type="hidden" name="old_image"  id="images"/>
             <GalleryUpload refreshFunction={this.updateFiles} />
             <label>Single image can be Upload</label>
            </div>
            
               <div className="col-md-4 col-lg-4 col-sm-4">
                                    <img id="img_preview"></img>
               </div>
                           
            <div className="col-md-12">
                <div className="buttonz">
                <button onClick={this.onSubmit} type="submit">Update</button>
                <button type="button">cancel</button>
                </div>
            </div>
            </div>
         
        </div>
     
        </form>
       
                     
</div>
</div>
</div>
</div>
</div>
        )
    }
}

export default Editgallery
