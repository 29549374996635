import React, { Component } from 'react'
import {Redirect} from 'react-router-dom';
import swal from 'sweetalert';

export class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
        redirect: false
    }

}

logout = () => {
    localStorage.removeItem("admintoken");
    localStorage.clear();
    this.setState({ redirect: true });
    swal({
        title: "Done!",
        text: "Logout Success",
        icon: "success",
        timer: 1000,
        button: false
      })

      window.location='/';

}
    render() {

      if (this.state.redirect) {
        return (
          
            <Redirect to={'/'} />
        )
    }
        return (
              <div>
                <div className="main-content">
            {/* responsive header */}
            <div className="panel-body">
              <div className="content-area sortable-widt">
                <div className="sub-bar">
                  <div className="sub-title">
                    <h4>Dashboard:</h4>
                    <span>Welcome To web Admin Panel!</span>
                  </div>
                  <ul className="bread-crumb">
                    <li><button  class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                    <li>Dashbord</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

    </div>
  
 


 

                
      
        )
    }
}

export default Dashboard
