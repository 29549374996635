import React, { Component } from 'react'
import { base_url } from '../constants';

import swal from 'sweetalert';


export class Allnewsletter extends Component {
     state = {
        newsletters: []
    } 
      componentDidMount() {
        fetch(`${base_url}/email/fetchmail`)
        .then((response) => response.json())
        .then(productList => {
            this.setState({ newsletters: productList });
        });
    }
    logout = () => {
        localStorage.removeItem("admintoken");
        localStorage.clear();
        this.setState({ redirect: true });
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
    
          window.location='/';
    
    }
    formatDate = (date) => {
        let d = new Date(date);
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    }
    render() {
        return (
            <div class="main-content">
            <div className="panel-body">
            <div className="content-area">
                <div className="sub-bar">
                <div className="sub-title">
                    <h4>Dashboard:</h4>
                    <span>Welcome To web Admin Panel!</span>
                </div>
                <ul className="bread-crumb">
                 <li><button   class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                    <li>Dashbord</li>
                </ul>
                </div>
                <div className="gap no-gap">
                <div className="inner-bg">
                    <div className="element-title">
                    <div className="col-md-12">
                        <div class="row">
                            <div class="col-lg-6">
                            <h4>Newsletter List</h4>
                            </div>
                    
                        </div>
                    </div>
                 </div>
                    <table className="cart-table table table-responsive">
                    <thead>
                        <tr>   
                        <th>Id</th>
                        <th>Name</th>
                        <th>Date</th>
                
                     
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.newsletters.map((p) => (
                        <tr> 
                        <td>{p._id}</td>
                        <td>{p.email}</td>
                        <td>{this.formatDate(p.newsletterDate)}</td>
                        </tr>
                ))}
                  
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
       </div>
        
    </div>
        )
    }
}

export default Allnewsletter
