import React, { Component } from 'react';
import axios from 'axios';
import EventUpload from '../utils/EventUpload';
import swal from 'sweetalert';
import { base_url } from '../constants';

export class Event extends Component {

       state = {
        eventtitle: '',
        description: '',
        images: '',
        category:[],
        price: '',
        discountprice:'',
        location:'',
        selectcategory:''
       }
    handleChangeTitle = (event) => {
        this.setState({ eventtitle: event.currentTarget.value })
    }
    handleChangeContinents = (event) => {
        this.setState({ selectcategory: event.currentTarget.value })
    }
    handleChangePrice = (event) => {
        this.setState({ price: parseInt(event.currentTarget.value, 10) })
    }

    handleChangeDecsription = (event) => {
        this.setState({ description: event.currentTarget.value })
    }
    handleChangediscountprice = (event) => {
        this.setState({ discountprice: event.currentTarget.value })
    }
    handleChangelocation = (event) => {
        this.setState({ location: event.currentTarget.value })
    }
    handleChangeTime = (event) => {
        this.setState({ time: event.currentTarget.value })
    }
    handleChangeDate = (event) => {
        this.setState({ eventdate: event.currentTarget.value })
    }
    componentDidMount() {
        fetch(`${base_url}/eventcategory/fetchcategory`)
        .then((response) => response.json())
        .then(productList => {
            this.setState({ category: productList });
        });
    }
    
    onSubmit = (event) => {
        event.preventDefault();
        
        if (!this.state.eventtitle || !this.state.description || !this.state.images || !this.state.discountprice || !this.state.price  || !this.state.category  || !this.state.time || !this.state.eventdate || !this.state.location) {
            return alert('Please first fill all the fields')
        }

        const variables = {
            eventtitle: this.state.eventtitle,
            category: this.state.selectcategory,
            description: this.state.description,
            images: this.state.images,
            price: this.state.price,
            discountprice: this.state.discountprice,
            location: this.state.location,
            time: this.state.time,
            eventdate: this.state.eventdate,

        }

        axios.post(`${base_url}/event/uploadEvent`, variables)
            .then(response => {
                if (response.data.success) {
                    swal({
                        title: "Done!",
                        text: "Success",
                        icon: "success",
                        timer: 1000,
                        button: false,
                        
                      })
                    window.location='/allevent';
            
                } else {
                    swal({
                        title: "Not Done!",
                        text: "Not Success",
                        icon: "warning",
                        timer: 1000,
                        button: false,
                        
                      })
                }
            })
    }

    updateFiles = (newImages) => {
        this.setState({ images: newImages })
    }
    logout = () => {
        localStorage.removeItem("admintoken");
        localStorage.clear();
        this.setState({ redirect: true });
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
          window.location='/';
    }

    render() {

        return (
            <div>


                    <div className="main-content">
                    <div className="panel-body">
                        <div className="content-area">
                        <div className="sub-bar">
                            <div className="sub-title">
                            <h4>Dashboard:</h4>
                            <span>Welcome To web Admin Panel!</span>
                            </div>
                            <ul className="bread-crumb">
                            <li><button class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                            <li>Event</li>
                            </ul>
                        </div>
                        <div className="gap no-gap">
                            <div className="inner-bg">
                            <div className="element-title">
                                <h4>Add Event</h4>
                                <span>Please fill the form bellow.</span> </div>
                            <form onSubmit={this.onSubmit}>
                            <div className="add-prod-from">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Event name</label>
                                    <input type="text" className="form-control"  name="eventtitle"  value={this.state.eventtitle}  onChange={this.handleChangeTitle}  required/>
                                </div>
                                <div className="col-md-6">
                                    <label>Event category</label>
                                   
                                    <select class="form-select"  onChange={this.handleChangeContinents}> 
                                    <option selected>Open this select Category</option>
                                        {this.state.category.map((c) => (
                                           <option key={c.key} value={c.categoryname}>{ c.categoryname}</option>
                              
                                       ))}
                                        </select> 
                            
                                      
                               
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                    <label>Event price</label>
                                    <span className="input-group-addon"><i className="fa fa-gbp" /></span>
                                    <input className="form-control" name="price"   type="text"  onChange={this.handleChangePrice}  value={this.state.price} required/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                    <label>Discount Price</label>
                                    <span className="input-group-addon"><i className="fa fa-gbp" /></span>
                                    <input className="form-control" name="discountprice"  value={this.state.discountprice} type="text"  onChange={this.handleChangediscountprice}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                    <label>Event Time</label>
                                    <span className="input-group-addon"><i className="fa fa-clock-o" /></span>
                                    <input className="form-control" name="time"   type="time"  onChange={this.handleChangeTime}  value={this.state.time} required/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                    <label>Event Date</label>
                                    <span className="input-group-addon"><i className="fa fa-calendar" /></span>
                                    <input className="form-control" name="eventdate"  value={this.state.eventdate} type="date"  onChange={this.handleChangeDate}/>
                                    </div>
                                </div>
        
                                <div className="col-md-6">
                                    <label>Event Description</label>
                                    <textarea rows={5} className="form-control" name="description"   onChange={this.handleChangeDecsription}  value={this.state.description}  placeholder="Write Description" required />
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                    <label>Event Location</label>
                                    <span className="input-group-addon"><i className="fa fa-map-marker" /></span>
                                    <input className="form-control" name="location"  value={this.state.location} type="text"  onChange={this.handleChangelocation}/>
                                    </div>
                                </div>
                             
                                <div className="col-md-6">
                                 <label>Event Image</label>
                                 <EventUpload refreshFunction={this.updateFiles} />
                                 <label>Single image can be Upload</label>
                                </div>
                                <div className="col-md-12">
                                    <div className="buttonz">
                                    <button onClick={this.onSubmit} type="submit">save</button>
                                    <button type="button">cancel</button>
                                    </div>
                                </div>
                                </div>
                             
                            </div>
                         
                            </form>
                           
                                         
                    </div>
               </div>
             </div>
   </div>
</div>
</div>
        )
    }
}

export default Event
