import React, { Component } from 'react'
import {Link,Redirect} from 'react-router-dom';
import swal from 'sweetalert';

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
        redirect: false
    }

}

logout = () => {
    localStorage.removeItem("admintoken");
    localStorage.clear();
    this.setState({ redirect: true });
    swal({
        title: "Done!",
        text: "Logout Success",
        icon: "success",
        timer: 1000,
        button: false
      })

}

  
    render() {

            if (this.state.redirect) {
            return (
              
                <Redirect to={'/'} />
            )
        }
   if(localStorage.getItem('admintoken')){
     
        return (
     <div>
  <header>
    <div className="side-menus">
      <div className="side-header">
        <div className="logo"><a title href="/"><img alt src="../assets/images/logo.png" /></a></div>
        <nav className="slide-menu">
          <ul className="parent-menu">
            <li><Link to="/dashboard" title><i className="fa fa-dashboard" /><span>Dashboard</span></Link>
            </li>
            <li className="menu-item-has-children"> <a title><i className="fa fa-shopping-cart" /><span>Product Category</span></a>
              <ul className="mega">
                <li><Link to="/category" title>Add Category</Link></li>
                <li><Link to="/allcategory">All Category</Link></li>
              </ul>
            </li>
            <li className="menu-item-has-children"> <a title><i className="fa fa-crosshairs" /><span>Event Category</span></a>
              <ul className="mega">
                <li><Link to="/eventcategory" title>Add Eventcategory</Link></li>
                <li><Link to="/alleventcategory">All Eventcategory</Link></li>
              </ul>
            </li>
            <li className="menu-item-has-children"> <a title><i className="fa fa-bullhorn" /><span>Product</span></a>
              <ul className="mega">
                <li><Link to="/product" title>Add new product</Link></li>
                <li><Link to="/allproduct">All Product</Link></li>
              </ul>
            </li>
            <li className="menu-item-has-children"> <a title><i className="fa fa-bullhorn" /><span>Event</span></a>
              <ul className="mega">
                <li><Link to="/event" title>Add new Event</Link></li>
                <li><Link to="/allevent">All Event</Link></li>     
              </ul>
            </li>
            <li className="menu-item-has-children"> <a title><i className="fa fa-crosshairs" /><span>Cms</span></a>
              <ul className="mega">
                <li><Link to="/gallery">Gallery</Link></li>
                <li><Link to="/allgallery">All Gallery</Link></li>
              </ul>
            </li>
            <li className="menu-item-has-children"> <a title><i className="fa fa-inbox" /><span>Order</span></a>
              <ul className="mega">
                <li><Link to="/allorder">Product Order</Link></li>
                <li><a href="/alleventorder">Event Order</a></li>
              </ul>
            </li>
            <li> <Link to="/alldonations"><i className="fa fa-crosshairs" /><span>All Donation</span></Link>
            </li>
            <li> <Link to="/allregister"><i className="fa fa-inbox" /><span>All Register</span></Link>
            </li>
            <li> <Link to="/allcontact" title><i className="fa fa-bullhorn" /><span>All Contact</span></Link>
            </li>
            <li> <Link to="/allnewsletter" title><i className="fa fa-bullhorn" /><span>All Newsletter</span></Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
  {/* side header */}
  <div className="topbar">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-2">
          <div className="logo"><a href="/" title><img src="../assets/images/logo.png" alt /></a></div>
        </div>
        <div className="col-lg-9">
          <ul className="notify-area">
            <li>
              <i className="fa fa-navicon nav-icon3" />
            </li>
          </ul>
        </div>
        <div className="col-lg-1">
  
        </div>
      </div>
    </div>
  </div>
</div>


        )
   }else
   {
     return (<div></div>)
   }
  
  
    }
}

export default Header
