import React, { Component } from 'react'
import {Link,Redirect} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { base_url } from '../constants';

export class Login extends Component {
    constructor(props) {
        super(props);
          this.state = {
          redirect: false
         };
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    
      }
        
      handleChangeEmail(e) {
        this.setState({ email: e.target.value });
    
      }
      handleChangePassword(e) {
        this.setState({ password: e.target.value });
    
      }

      handleSubmit(event) {
        event.preventDefault();
    
          const data = {
            email : this.state.email,
            password : this.state.password,
      
          }
          axios.post(`${base_url}/adminlogin/login`,data).
          then(res=>{
            console.log(res.data.token) 
            localStorage.setItem('admintoken',res.data.token);
            this.setState({ redirect: true });
            swal({
              title: "Done!",
              text: "Login Success",
              icon: "success",
              timer: 1000,
              button: false,
              
            })
            window.location='/dashboard';
           
            
        }).catch(() => {
          swal({
            title: "Not Done!",
            text: "LogIn not Success",
            icon: "warning",
            timer: 1000,
            button: false,
            
          })
          
        });
          
    
        
      
    
      }
    
    render() {
        if (this.state.redirect) {
            return (
              
                <Redirect to={'/dashboard'} />
            )
        }
        return (
            <div>
          <div className="panel-layout">
            <div className="container-fluid">
                <div className="row">
                <div className="col-md-12">
                    <div className="admin-lock vh100">
                    <div className="admin-form">
                        <h4>Sign In Account</h4>
                        <span>Please enter your user information</span>
                        <form onSubmit={this.handleSubmit}>
                        <label><i className="fa fa-envelope" /></label>
                        <input type="text" className="form-control" name="email"   value={this.state.email} onChange={this.handleChangeEmail} placeholder="Email Address" />
                        <label><i className="fa fa-unlock-alt" /></label>
                        <input type="password" className="form-control"  placeholder="Password" name="password"  value={this.state.password}  onChange={this.handleChangePassword} />
                        <button type="submit">sign in</button>
                        </form>
                         {/*<span>Don't have an account? <Link to="/register" title>Register</Link></span>*/} </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

                
            </div>
        )
    }
}

export default Login
