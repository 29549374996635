import React, { Component } from 'react'
import { base_url } from '../constants';
import swal from 'sweetalert';

export class Allcontact extends Component {
    state = {
        contact: []
    }
    
      componentDidMount() {
        fetch(`${base_url}/contact/fetchcontact`)
        .then((response) => response.json())
        .then(productList => {
            this.setState({ contact: productList });
        });
    }
    logout = () => {
        localStorage.removeItem("admintoken");
        localStorage.clear();
        this.setState({ redirect: true });
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
    
          window.location='/';
    
    }
    render() {
        return (
            <div class="main-content">
            <div className="panel-body">
            <div className="content-area">
                <div className="sub-bar">
                <div className="sub-title">
                    <h4>Dashboard:</h4>
                    <span>Welcome To web Admin Panel!</span>
                </div>
                <ul className="bread-crumb">
                 <li><button   class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                    <li>Contact</li>
                </ul>
                </div>
                <div className="gap no-gap">
                <div className="inner-bg">
                    <div className="element-title">
                    <div className="col-md-12">
                        <div class="row">
                            <div class="col-lg-6">
                            <h4>Contact List</h4>
                            </div>
                        </div>
                    </div>
                 </div>
                    <table className="cart-table table table-responsive">
                    <thead>
                        <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Number</th>
                        <th>Subject</th>
                        <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.contact.map((g) => (
                        <tr>
                        <td>{g.name}</td>
                        <td>{g.email}</td>
                        <td>{g.phone}</td>
                        <td>{g.subject}</td>
                        <td>{g.message}</td>   
                        </tr>
))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        
    </div>
        )
    }
}

export default Allcontact

