import React, { Component } from 'react'
import swal from 'sweetalert';
 import axios from 'axios';
 import { base_url } from '../constants';

export class Alleventorder extends Component {
    state = {
        foundOrder: []
    }
    componentDidMount() {
        fetch(`${base_url}/eventapi/fetcheventorder`)
        .then((response) => response.json())
        .then(orderList => {
            this.setState({ foundOrder: orderList });
        });

    }

logout = () => {
    localStorage.removeItem("admintoken");
    localStorage.clear();
    this.setState({ redirect: true });
    swal({
        title: "Done!",
        text: "Logout Success",
        icon: "success",
        timer: 1000,
        button: false
      })

      window.location='/';

}
formatDate = (date) => {
    let d = new Date(date);
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
}
getOrderTotal = (id) => {
    const singleOrder = this.state.foundOrder.find(order => order._id === id);
    let orderTotal = 0;
    singleOrder.order.forEach(order => {
        orderTotal = orderTotal + (order.price )
    });

    return orderTotal;
}
    render() {
        return (
            <div class="main-content">
            <div className="panel-body">
            <div className="content-area">
                <div className="sub-bar">
                <div className="sub-title">
                    <h4>Allorder:</h4>
                    <span>Welcome To web Admin Panel!</span>
                </div>
                <ul className="bread-crumb">
                 <li><button   class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                    <li>AllEventorder</li>
                </ul>
                </div>
                <div className="gap no-gap">
                <div className="inner-bg">
                    <div className="element-title">
                    <div className="col-md-12">
                        <div class="row">
                            <div class="col-lg-6">
                            <h4>Event Order List</h4>
                            </div>
            
                        </div>
                    </div>
                 </div>
                    <table className="cart-table table table-responsive">
                    <thead>
                        <tr>
                      
                        <th>Event Id</th>
                        <th>Book Date</th>
                        <th>User Name</th>
                        <th>paymentType</th>
                        <th>paymentStatus</th>
                        <th>Event Details</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.foundOrder.map((order) => (
  
                        <tr>
                             <td>{order._id}</td>
                             <td>{this.formatDate(order.orderDate)}</td>
                             <td>{order.username}</td>
                             {/* <td>{order.isOrderCompleted}</td> */}
                             <td>{order.paymentType}</td>
                             <td>{order.paymentStatus}</td>
                             {order.order.map(item => (
                                                <div key={item._id} style={{display: 'flex', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #cecece'}}>
                                                       <div className="ImageContainer">
                                                        <img style={{maxWidth: '100%', maxHeight: '100%'}} src={`${base_url}/eventImages/`+ item.image}/>
                                                       </div>
                                                       <div className="col-lg-4">
                                                <h4><span>{item.name}</span></h4>
                                                            <div>
                                                            <h5>£{item.price }</h5>
             </div>
                                    
                                                        
                                                       
                                                    </div>
                                                </div>
                                            ))}
                            <h4>Total Price <span>£{this.getOrderTotal(order._id)}</span></h4>
                        </tr>
                    
                        )
                        )}
            
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        
    </div>
        )
    }
}

export default Alleventorder
