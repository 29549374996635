import React, { Component } from 'react'
import {Link,Redirect} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { base_url } from '../constants';

export class Register extends Component {

    constructor(props) {
        super(props);
          this.state = {
          redirect: false
         };
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        
    
      }
      
      
      handleChangeName(e) {
        this.setState({ name: e.target.value });
    
      }
      handleChangeEmail(e) {
        this.setState({ email: e.target.value });
    
      }
      handleChangePassword(e) {
        this.setState({ password: e.target.value });
    
      }

   
      handleSubmit(event) {
        event.preventDefault();
    
          const adminregister = {
            username : this.state.name,
              email : this.state.email,
            password : this.state.password,
      
          }
      
      
          axios.post(`${base_url}/adminlogin/signup`,adminregister).
          then(()=>{ 
            this.setState({ redirect: true });
            swal({
              title: "Done!",
              text: "Register Success",
              icon: "success",
              timer: 1000,
              button: false,
              
            })
      
            
        }).catch(() => {
          swal({
            title: "Not Done!",
            text: "Register not Success",
            icon: "warning",
            timer: 1000,
            button: false,
            
          })
          
        });
    
      }
    
    
    render() {

      if (this.state.redirect) {
        return (
          
            <Redirect to={'/'} />
            
        

        )
    }

        return (
         
                <div className="panel-layout">
            <div className="container-fluid">
                <div className="row">
                <div className="col-md-12">
                    <div className="admin-lock vh100">
                    <div className="admin-form">
                        <h4>Sign Up Account</h4>
                        <span>Please enter your user information</span>
                        <form onSubmit={this.handleSubmit}>
                        <label><i className="fa fa-user-circle-o" /></label>
                        <input type="text" placeholder="Name"  name="name"  value={this.state.name} onChange={this.handleChangeName} className="form-control" />
                    
                        <label><i className="fa fa-envelope" /></label>
                        <input type="text" placeholder="Email Address"   name="email"   value={this.state.email} onChange={this.handleChangeEmail} className="form-control" />
                    
                        <label><i className="fa fa-unlock-alt" /></label>
                        <input type="password" placeholder="Password" name="password"  value={this.state.password}  onChange={this.handleChangePassword} className="form-control" />
                        <button type="submit">sign up</button>
                        </form>
                        <span>Have an account..? <Link to="/" title>Login</Link></span> </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        )
    }
}

export default Register
