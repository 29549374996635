import React, { Component } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import { base_url } from '../constants';

export class Category extends Component {

    constructor(props) {
        super(props);
          this.state = {
          redirect: false
         };
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    
      }
        
      handleChangeCategory(e) {
        this.setState({ categoryname: e.target.value });
    
      }
  
      handleSubmit(event) {
        event.preventDefault();
    
          const categories = {
            categoryname : this.state.categoryname,
      
          }
          axios.post(`${base_url}/category/createcategory`,categories).
          then(res=>{
            this.setState({ redirect: true });
            swal({
              title: "Done!",
              text: "Success",
              icon: "success",
              timer: 1000,
              button: false,
              
            })
            window.location='/allcategory';
           
            
        }).catch(() => {
          swal({
            title: "Done!",
            text: "Inserted",
            icon: "warning",
            timer: 1000,
            button: false,
            
          })
          
        });
    
      }
      logout = () => {
        localStorage.removeItem("admintoken");
        localStorage.clear();
        this.setState({ redirect: true });
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
    
          window.location='/';
    
    }
    render() {
        
        return (
            <div>
<div className="main-content">
<div className="panel-body">
    <div className="content-area">
    <div className="sub-bar">
        <div className="sub-title">
        <h4>Dashboard:</h4>
        <span>Welcome To web Admin Panel!</span>
        </div>
        <ul className="bread-crumb">
        <li><button  class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
        <li>Category</li>
        </ul>
    </div>
    <div className="gap no-gap">
        <div className="inner-bg">
        <div className="element-title">
            <h4>Add Category</h4>
            <span>Please fill the form bellow.</span> </div>
        <form onSubmit={this.handleSubmit} >
        <div className="add-prod-from">
        <div className="row">
            <div className="col-md-6">
                <label>Category Name</label>
                <input type="text" className="form-control"  name="categoryname" value={this.state.categoryname} onChange={this.handleChangeCategory}  required/>
            </div>
    
            <div className="col-md-12">
                <div className="buttonz">
                <button   value="submit"  type="submit">save</button>
                <button type="button">cancel</button>
                </div>
            </div>
            </div>
        </div>
        </form>            
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
       
        )
    }
}

export default Category
