import React, { Component } from 'react'
import GalleryUpload from '../utils/GalleryUpload';
import swal from 'sweetalert';
import axios from 'axios';
import { base_url } from '../constants';

export class Gallery extends Component {

    state = {
          images: '',
       }
  
    onSubmit = (event) => {
        event.preventDefault();
        
        if (!this.state.images ) {
            return alert('only single images upload')
        }

        const variables = {
          
            images: this.state.images,
           

        }

        axios.post(`${base_url}/gallery/uploadGallery`, variables)
            .then(response => {
                if (response.data.success) {
                    swal({
                        title: "Done!",
                        text: "Success",
                        icon: "success",
                        timer: 1000,
                        button: false,
                        
                      })
                    window.location='/allgallery';
                } else {
                    swal({
                        title: "Not Done!",
                        text: "Not Success",
                        icon: "warning",
                        timer: 1000,
                        button: false,
                        
                      })
                }
            })
    }
    logout = () => {
        localStorage.removeItem("admintoken");
        localStorage.clear();
        this.setState({ redirect: true });
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
    
          window.location='/';
    
    }
    updateFiles = (newImages) => {
        this.setState({ images: newImages })
    }
    render() {
        return (



<div className="main-content">
<div className="panel-body">
    <div className="content-area">
    <div className="sub-bar">
        <div className="sub-title">
        <h4>Dashboard:</h4>
        <span>Welcome To web Admin Panel!</span>
        </div>
        <ul className="bread-crumb">
        <li><button  class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
        <li>Gallery</li>
        </ul>
    </div>
    <div className="gap no-gap">
        <div className="inner-bg">
        <div className="element-title">
            <h4>Add Gallery</h4>
            <span>Please fill the form bellow.</span> </div>
        <form onSubmit={this.onSubmit}>
        <div className="add-prod-from">
        <div className="row">
            <div className="col-md-6">
             <label>Gallery Image Upload</label>
             <GalleryUpload refreshFunction={this.updateFiles} />
             <label>Single image can be Upload</label>
            </div>
            <div className="col-md-12">
                <div className="buttonz">
                <button onClick={this.onSubmit} type="submit">save</button>
                <button type="button">cancel</button>
                </div>
            </div>
            </div>
         
        </div>
     
        </form>
       
                     
</div>
</div>
</div>
</div>
</div>


        )
    }
}

export default Gallery
