import React, { Component } from 'react'
import axios from 'axios';
import EventUpload from '../utils/EventUpload';
import swal from 'sweetalert';
import { base_url } from '../constants';


export class Editevent extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            eventtitle: '',
            description: '',
            images: '',
            category:[],
            price: '',
            discountprice:'',
            location:'',
            product:null,
            selectcategory:''
          
        };
      }
      handleChangeTitle = (event) => {
        this.setState({ eventtitle: event.currentTarget.value })
    }
    handleChangeContinents = (event) => {
        this.setState({ selectcategory: event.currentTarget.value })
    }
    handleChangePrice = (event) => {
        this.setState({ price: event.currentTarget.value })
    }

    handleChangeDecsription = (event) => {
        this.setState({ description: event.currentTarget.value })
    }
    handleChangediscountprice = (event) => {
        this.setState({ discountprice: event.currentTarget.value })
    }
    handleChangelocation = (event) => {
        this.setState({ location: event.currentTarget.value })
    }
    handleChangeTime = (event) => {
        this.setState({ time: event.currentTarget.value })
    }
    handleChangeDate = (event) => {
        this.setState({ eventdate: event.currentTarget.value })
    }
    componentDidMount= async () => {       
        let evenId = this.props.match.params.id; 
        fetch(`${base_url}/event/`+evenId)
        .then(response => response.json())
        .then(jsonResponse => {
                if(jsonResponse.hasOwnProperty('message')){
                    console.log(jsonResponse);
                    this.setState({
                        product: jsonResponse.message
                    })
                    this.state.eventtitle=this.state.product.eventtitle ; 
                    this.state.price = this.state.product.price;
                    this.state.category=this.state.product.category ;
                    this.state.discountprice = this.state.product.discountprice;
                    this.state.description =this.state.product.description;
                    this.state.discountprice = this.state.product.discountprice;
                    this.state.location =this.state.product.location;
                    this.state.eventdate =this.state.product.eventdate;
                    this.state.time =this.state.product.time;
                    this.state.images =this.state.product.images;
                   // alert(JSON.stringify(this.state.product.price));
                     
                    document.getElementById('eventtitle').value = this.state.product.eventtitle ; 
                    document.getElementById('price').value = this.state.product.price ;
                    document.getElementById('category').value = this.state.product.category ;
                    document.getElementById('catg_hidden').value = this.state.product.category ;
                    document.getElementById('discountprice').value = this.state.product.discountprice ;
                    document.getElementById('description').value = this.state.product.description ; 
                    document.getElementById('time').value = this.state.product.time ; 
                    document.getElementById('eventdate').value = this.state.product.eventdate ; 
                    document.getElementById('location').value = this.state.product.location ; 
                    document.getElementById('images').value = this.state.product.images ;  
                    document.getElementById("img_preview").src=`${base_url}/eventImages/`+ this.state.product.images;     
                }
        })
        .catch(error => {
            console.log(error);
        });
        fetch(`${base_url}/eventcategory/fetchcategory`)
        .then((response) => response.json())
        .then(productList => {
            this.setState({ category: productList });
        });
      }
      onSubmit = (event) => {
        event.preventDefault();
        if (!this.state.category) {
            return alert('choose a category')
        }

        var disct = this.state.discountprice ;
        if(disct== "")
        {
          disct=0;  
        }
        const variables = {
            eventtitle: this.state.eventtitle,
            category: this.state.selectcategory,
            old_catg:document.getElementById('catg_hidden').value,
            description: this.state.description,
            images: this.state.images,
            price: this.state.price,
            discountprice: disct,
            location: this.state.location,
            time: this.state.time,
            eventdate: this.state.eventdate,
        }
        console.log(variables);
        let prodId = this.props.match.params.id; 
        axios.post(`${base_url}/event/update/` + prodId, variables)
            .then(response => {
                if (response.data.success) {
                    swal({
                        title: "Done!",
                        text: "Success",
                        icon: "success",
                        timer: 1000,
                        button: false,
                        
                      })
                   
                }
                window.location='/allevent';
            })
    }
      updateFiles = (newImages) => {
        this.setState({ images: newImages })
    }
 
    render() {
        return (
            <div>


            <div className="main-content">
            <div className="panel-body">
                <div className="content-area">
                <div className="sub-bar">
                    <div className="sub-title">
                    <h4>Dashboard:</h4>
                    <span>Welcome To web Admin Panel!</span>
                    </div>
                    <ul className="bread-crumb">
                    <li><button class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                    <li>Event</li>
                    </ul>
                </div>
                <div className="gap no-gap">
                    <div className="inner-bg">
                    <div className="element-title">
                        <h4>Edit Event</h4>
                        <span>Please fill the form bellow.</span> </div>
                    <form onSubmit={this.onSubmit}>
                    <div className="add-prod-from">
                    <div className="row">
                        <div className="col-md-6">
                            <label>Event name</label>
                            <input type="text" className="form-control"  name="eventtitle" id="eventtitle" onChange={this.handleChangeTitle}  required/>
                        </div>
                        <div className="col-md-6">
                                    <input type="hidden" id="catg_hidden" />
                                    <label>product category</label>
                                    <select class="form-select" id="category"  name="selectcategory" onChange={this.handleChangeContinents}> 
                                    {/* <option selected>Open this select Category</option> */}
                                    {this.state.category.map((c) => (
                                            c.categoryname == document.getElementById('catg_hidden').value?(                                        
                                       <option selected  key={c.key} value={c.categoryname}>{ c.categoryname}</option>
                                            ):
                                        ( <option   key={c.key} value={c.categoryname}>{ c.categoryname}</option>)
                                     ))}
                                    </select> 
                                </div>
                        <div className="col-md-6">
                            <div className="input-group">
                            <label>Event price</label>
                            <span className="input-group-addon"><i className="fa fa-gbp" /></span>
                            <input className="form-control" name="price"   type="text" id="price"   onChange={this.handleChangePrice}   required/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group">
                            <label>Discount Price</label>
                            <span className="input-group-addon"><i className="fa fa-gbp" /></span>
                            <input className="form-control" name="discountprice"  id="discountprice"  type="text"  onChange={this.handleChangediscountprice}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group">
                            <label>Event Time</label>
                            <span className="input-group-addon"><i className="fa fa-clock-o" /></span>
                            <input className="form-control" name="time"   type="time" id="time"  onChange={this.handleChangeTime}  required/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group">
                            <label>Event Date</label>
                            <span className="input-group-addon"><i className="fa fa-calendar" /></span>
                            <input className="form-control" name="eventdate"  type="date"  id="eventdate" onChange={this.handleChangeDate}/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label>Event Description</label>
                            <textarea rows={5} className="form-control" name="description" id="description"    onChange={this.handleChangeDecsription}   placeholder="Write Description" required />
                        </div>
                        <div className="col-md-6">
                            <div className="input-group">
                            <label>Event Location</label>
                            <span className="input-group-addon"><i className="fa fa-map-marker" /></span>
                            <input className="form-control" name="location"  type="text" id="location" onChange={this.handleChangelocation}/>
                            </div>
                        </div>
                     
                        <div className="col-md-6">
                         <label>Event Image</label>
                         <input type="hidden" name="old_image"  id="images"/>
                         <EventUpload refreshFunction={this.updateFiles} />
                         <label>Single image can be Upload</label>
                        </div>
                        <div className="row">
                                    <div className="col-md-4 col-lg-4 col-sm-4">
                                    <img id="img_preview"></img>
                                    </div>
                                </div>
                        <div className="col-md-12">
                            <div className="buttonz">
                            <button value="Save"  type="submit" >Update</button>
                            <button type="button">cancel</button>
                            </div>
                        </div>
                        </div>
                     
                    </div>
                 
                    </form>
                   
                                 
            </div>
       </div>
     </div>
</div>
</div>
</div>
        )
    }
}

export default Editevent
