import React, { Component } from 'react';
import axios from 'axios';
import FileUpload from '../utils/FileUpload';
import swal from 'sweetalert';
import { base_url } from '../constants';


export class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            producttitle: '',
            description: '',
            images: '',
            price: '',
            discountprice:'',
            category:[],
            selectcategory:''
          
        }
      }
    
    handleChangeTitle = (event) => {
        this.setState({ producttitle: event.currentTarget.value })
    }
    handleChangeContinents = (event) => {
        this.setState({ selectcategory: event.currentTarget.value })
    }
    handleChangePrice = (event) => {
        this.setState({ price: event.currentTarget.value })
    }

    handleChangeDecsription = (event) => {
        this.setState({ description: event.currentTarget.value })
    }
    handleChangediscountprice = (event) => {
        this.setState({ discountprice: event.currentTarget.value })
    }

    componentDidMount() {
        fetch(`${base_url}/category/fetchcategory`)
            .then((response) => response.json())
           .then(productList => {
            this.setState({ category: productList });
        });
      
    }
    onSubmit = (event) => {
        event.preventDefault();
        
        if (!this.state.producttitle || !this.state.description || !this.state.images || !this.state.discountprice || !this.state.price) {
            return alert('Please first fill all the fields')
        }

        const variables = {
            producttitle: this.state.producttitle,
            category: this.state.selectcategory,
            description: this.state.description,
            images: this.state.images,
            price: this.state.price,
            discountprice: this.state.discountprice,

        }
        axios.post(`${base_url}/product/uploadProduct`, variables)
            .then(response => {
                if (response.data.success) {
                    swal({
                        title: "Done!",
                        text: "Success",
                        icon: "success",
                        timer: 1000,
                        button: false,
                        
                      })
                    window.location='/allproduct';
                } else {
                    swal({
                        title: "Not Done!",
                        text: "Success",
                        icon: "warning",
                        timer: 1000,
                        button: false,
                        
                      })
                }
            })
    }
    logout = () => {
        localStorage.removeItem("admintoken");
        localStorage.clear();
        this.setState({ redirect: true });
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
    
          window.location='/';
    
    }
    updateFiles = (newImages) => {
        this.setState({ images: newImages })
    }
    render() {
 
        return (
            <div>
   <div className="main-content">
                    <div className="panel-body">
                        <div className="content-area">
                        <div className="sub-bar">
                            <div className="sub-title">
                            <h4>Dashboard:</h4>
                            <span>Welcome To web Admin Panel!</span>
                            </div>
                            <ul className="bread-crumb">
                            <li><button  class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                            <li>Product</li>
                            </ul>
                        </div>
                        <div className="gap no-gap">
                            <div className="inner-bg">
                            <div className="element-title">
                                <h4>Add Product</h4>
                                <span>Please fill the form bellow.</span> </div>
                            <form onSubmit={this.onSubmit}>
                            <div className="add-prod-from">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>product name</label>
                                    <input type="text" className="form-control"  name="producttitle" onChange={this.handleChangeTitle}  required/>
                                </div>
                                <div className="col-md-6">
                                    <label>product category</label>
                                   
                                    <select class="form-select"  onChange={this.handleChangeContinents}> 
                                    <option selected>Open this select Category</option>
                                    {this.state.category.map((c) => (
                                       <option key={c.key} value={c.categoryname}>{ c.categoryname}</option>
                            
                                     ))}
                                    </select> 
                            
                                      
                               
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                    <label>Sell price</label>
                                    <span className="input-group-addon"><i className="fa fa-gbp" /></span>
                                    <input className="form-control" name="price" type="text"  onChange={this.handleChangePrice}  required/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                    <label>discount Price</label>
                                    <span className="input-group-addon"><i className="fa fa-gbp" /></span>
                                    <input className="form-control" name="discountprice" type="text"  onChange={this.handleChangediscountprice} required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Product Description</label>
                                    <textarea rows={6} className="form-control" name="description"   onChange={this.handleChangeDecsription}   placeholder="Write Description" required />
                                </div>
                             
                                <div className="col-md-6">
                                 <label>Product Image</label>
                                 <FileUpload refreshFunction={this.updateFiles} />
                                 <label>Single image can be Upload</label>
                                </div>
                                <div className="col-md-12">
                                    <div className="buttonz">
                                    <button   value="Save"  type="submit">save</button>
                                    <button type="button">cancel</button>
                                    </div>
                                </div>
                                </div>
                             
                            </div>
                         </form>
                           
                                         
                    </div>
               </div>
             </div>
   </div>
</div>
</div>
        )
    }
}

export default Product
