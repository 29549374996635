import React, { Component } from 'react'
import Header from "./include/Header.js";
import Footer from "./include/Footer.js";
import Dashboard from "./admin/Dashboard.js";
import Product from "./admin/Product";
import Allproduct from "./admin/Allproduct.js";
import Editproduct from "./admin/Editproduct.js";
import Login from "./admin/Login.js";
import Register from "./admin/Register.js";
import Event from "./admin/Event.js";
import Allevent from "./admin/Allevent.js";
import Gallery from "./admin/Gallery.js";
import Allgallery from "./admin/Allgallery.js";
import Allorder from "./admin/Allorder.js";
import Category from "./admin/Category.js";
import Allcategory from "./admin/Allcategory.js";
import Alldonation from "./admin/Alldonation.js";
import Editevent from "./admin/Editevent.js";
import Editcategory from "./admin/Editcategory.js";
import Eventcategory from "./admin/Eventcategory.js";
import Alleventcategory from "./admin/Alleventcategory.js";
import EditeventCategory from "./admin/EditeventCategory.js";
import Editgallery from "./admin/Editgallery.js";
import Allcontact from "./admin/Allcontact.js";
import Alleventorder from "./admin/Alleventorder.js";
import Allregister from "./admin/Allregister.js";
import Allnewsletter from "./admin/Allnewsletter.js";
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';

export class App extends Component {
  render() {
    if(localStorage.getItem('admintoken')){
    return (
      
      <div>
        
          <Router>
              
              <Header/>
             <Switch> 
             <Route  path="/dashboard" component={Dashboard} /> 
             <Route path="/product" component={Product} />
              <Route path="/allproduct" component={Allproduct} />
              <Route path="/edit/:id" component={Editproduct} />
              <Route path="/event" component={Event } />
              <Route path="/category" component={Category } />
              <Route path="/allcategory" component={Allcategory } />
              <Route path="/editcategory/:id" component={Editcategory } />
              <Route path="/alldonations" component={Alldonation } />
              <Route path="/editevent/:id" component={Editevent } />
              <Route path="/eventcategory" component={Eventcategory } />
              <Route path="/alleventcategory" component={Alleventcategory } />
              <Route path="/editeventcategory/:id" component={EditeventCategory } />
              <Route path="/editgallery/:id" component={Editgallery } />
              <Route path="/allcontact" component={Allcontact } />
              <Route path="/alleventorder" component={Alleventorder } />
              <Route path="/allregister" component={Allregister } />
              <Route path="/allnewsletter" component={Allnewsletter } />
              <Route path="/allevent" component={localStorage.getItem('admintoken')?Allevent:Login  } />
              <Route path="/gallery" component={localStorage.getItem('admintoken')?Gallery:Login  } />
              <Route path="/allgallery" component={localStorage.getItem('admintoken')?Allgallery:Login  } />
              <Route path="/allorder" component={localStorage.getItem('admintoken')?Allorder:Login  } />  
             
          </Switch>        
           <Footer/>
          </Router>
     
       

        </div>
    )
    }else
    {
      return(
      <div>
        <Router><Route  exact path="/" component={Login}/>
        <Route path="/register" component={Register} />
        </Router>
      </div>
      )
    }
  }
}

export default App
