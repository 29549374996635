import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
          
              <div className="bottombar">
                           <span>© 2021</span>
               </div>

        )
    }
}

export default Footer
