import React, { Component } from 'react'
import swal from 'sweetalert';
import { base_url } from '../constants';

export class Alldonation extends Component {
    state = {
        donations: []
        }
      componentDidMount() {
            fetch(`${base_url}/test/fetchdetailsdonation`)
            .then((response) => response.json())
            .then(orderList => {
                this.setState({ donations: orderList });
            });
    
        }
      
    logout = () => {
        localStorage.removeItem("admintoken");
        localStorage.clear();
        this.setState({ redirect: true });
        swal({
            title: "Done!",
            text: "Logout Success",
            icon: "success",
            timer: 1000,
            button: false
          })
    
          window.location='/';
    
    }
    formatDate = (date) => {
        let d = new Date(date);
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    } 
    getOrderTotal = (id) => {
        const singleOrder = this.state.donations.find(donation => donation._id === id);
        let orderTotal = 0;
        singleOrder.donation.forEach(donation => {
            orderTotal = orderTotal + (donation.amount )
        });

        return orderTotal;
    }
    render() {
        return (
            <div class="main-content">
            <div className="panel-body">
            <div className="content-area">
                <div className="sub-bar">
                <div className="sub-title">
                    <h4>Dashboard:</h4>
                    <span>Welcome To web Admin Panel!</span>
                </div>
                <ul className="bread-crumb">
                 <li><button   class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                    <li>All Donation</li>
                </ul>
                </div>
                <div className="gap no-gap">
                <div className="inner-bg">
                    <div className="element-title">
                    <div className="col-md-12">
                        <div class="row">
                            <div class="col-lg-6">
                            <h4>Donation List</h4>
                            </div>
                        </div>
                    </div>
                 </div>
                    <table className="cart-table table table-responsive">
                    <thead>
                        <tr>   
                        <th>DonationId</th>
                        <th>Donation Date</th>
                        <th>User</th>
                        <th>paymentType</th>
                        <th>paymentStatus</th>
                        <th>Donation Details</th>

                        </tr>
                    </thead>
                    <tbody>
                    {this.state.donations.map((p) => (
                        <tr> 
                          <td>{p._id}</td>
                             <td>{this.formatDate(p.orderDate)}</td>
                             {p.donation.map(item => (
                             <td>{item.fullName}</td>
                             ))}
                             {/* <td>{order.isOrderCompleted}</td> */}
                             <td>{p.paymentType}</td>
                             <td>{p.paymentStatus}</td>
                             {p.donation.map(item => (
                                                <div key={item._id} style={{display: 'flex', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #cecece'}}>
                                                    <div className="col-lg-4">
                                                                <p><span>{item.fullName}</span></p>
                                                        <div>
                                                        <h5>£{item.mobileNumber }</h5>
                                                        <p>Qty:{item.amount} </p>
                                                    </div>
                                                    
                                                                        
                                                       
                                                    </div> 
          </div>
                                  
                                  ))}
                                     <h4>Total Price <span>£{this.getOrderTotal(p._id)}</span></h4>
                        </tr>
                        
                        
                ))}
                
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
       </div>
        
    </div>
)
}
}
 

export default Alldonation
