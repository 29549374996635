import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import { base_url } from '../constants';

export class Allcategory extends Component {
    state = {
        category: []
    }
    
      componentDidMount() {
        fetch(`${base_url}/category/fetchcategory`)
        .then((response) => response.json())
        .then(productList => {
            this.setState({ category: productList });
        });
    }
    deleteExercise(id) {
        axios.delete(`${base_url}/category/`+id)
          .then(response => { 
               window.location='/allcategory';
              console.log(response.data)}
              
              )
        
          .catch((error) => {
            console.log(error);
          })
      }
    
logout = () => {
    localStorage.removeItem("admintoken");
    localStorage.clear();
    this.setState({ redirect: true });
    swal({
        title: "Done!",
        text: "Logout Success",
        icon: "success",
        timer: 1000,
        button: false
      })

      window.location='/';

}
    render() {
        return (
            <div class="main-content">
            <div className="panel-body">
            <div className="content-area">
                <div className="sub-bar">
                <div className="sub-title">
                    <span>Welcome To web Admin Panel!</span>
                </div>
                <ul className="bread-crumb">
                 <li><button   class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                    <li>Allcategory</li>
                </ul>
                </div>
                <div className="gap no-gap">
                <div className="inner-bg">
                    <div className="element-title">
                    <div className="col-md-12">
                        <div class="row">
                            <div class="col-lg-6">
                            <h4>Category List</h4>
                            </div>
                            <div class="col-lg-6 text-right">
                               <Link to="/category"><button  className="btn btn-primary" type="submit">Add Category</button></Link>
                             </div>
                        </div>
                    </div>
                 </div>
                    <table className="cart-table table table-responsive">
                    <thead>
                        <tr>
                        <th>Category Name</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.category.map((c) => (
                        <tr>
                        <td>{ c.categoryname}</td>
                        <td><button><Link to={"/editcategory/"+ c._id}><i className="icon-pencil" /></Link></button></td>
                       <td><button  onClick={() => { this.deleteExercise(c._id) }}><i class="icon-trash"></i></button> </td>
                        </tr>
))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        
    </div>
        )
    }
}

export default Allcategory
