import React, { Component } from 'react';
import FileUpload from '../utils/FileUpload';
import axios from 'axios';
import swal from 'sweetalert';
import { base_url } from '../constants';

export class Editproduct extends Component {
   constructor(props) {
        super(props);
       this.state = {
            producttitle: '',
            description: '',
            images: '',
            price: '',
            discountprice:'',
            category:[],
            selectcategory:'',
            product:null
          
        };
      }
      handleChangeTitle = (event) => {
        this.setState({ producttitle: event.currentTarget.value })
    }
    handleChangeContinents = (event) => {
        this.setState({ selectcategory: event.currentTarget.value })
    }
    handleChangePrice = (event) => {
        this.setState({ price: parseInt(event.currentTarget.value, 10) })
    }

    handleChangeDecsription = (event) => {
        this.setState({ description: event.currentTarget.value })
    }
    handleChangediscountprice = (event) => {
        this.setState({ discountprice: event.currentTarget.value })
    }

    componentDidMount= async () => {       
        let prodId = this.props.match.params.id; 
        fetch(`${base_url}/product/`+prodId)
        .then(response => response.json())
        .then(jsonResponse => {
                if(jsonResponse.hasOwnProperty('message')){
                    console.log(jsonResponse);
                    this.setState({
                        product: jsonResponse.message
                    })

                   // alert(JSON.stringify(this.state.product.price));
                     this.state.producttitle=this.state.product.producttitle ; 
                     this.state.price = this.state.product.price;
                     this.state.category=this.state.product.category ;
                     this.state.discountprice = this.state.product.discountprice;
                     this.state.description =this.state.product.description;
                     this.state.images =this.state.product.images;
                     
                     
                    document.getElementById('producttitle').value = this.state.product.producttitle ; 
                    document.getElementById('price').value = this.state.product.price ;
                    document.getElementById('category').value = this.state.product.category ;
                    document.getElementById('catg_hidden').value = this.state.product.category ;
                    document.getElementById('discountprice').value = this.state.product.discountprice ;
                    document.getElementById('description').value = this.state.product.description ; 
                    document.getElementById('images').value = this.state.product.images ;  
                    document.getElementById("img_preview").src=`${base_url}/productImages/`+ this.state.product.images;     
                }
        })
        .catch(error => {
            console.log(error);
        });
       //alert(JSON.stringify(this.state.product))

       fetch(`${base_url}/category/fetchcategory`)
       .then((response) => response.json())
       .then(productList => {
       this.setState({ category: productList });
   });
      }
      onSubmit = (event) => {
        event.preventDefault();
        if (!this.state.category) {
            return alert('Please Select a categoty')
        }
        var disct = this.state.discountprice ;
        if(disct=="")
        {
          disct=0;  
        }
        const variables = {
            producttitle: this.state.producttitle,
            category: this.state.selectcategory,
            description: this.state.description,
            images: this.state.images,
            price: this.state.price,
            discountprice: disct,
        }
        console.log(variables);
        let prodId = this.props.match.params.id; 
        axios.post(`${base_url}/product/update/` + prodId, variables)
            .then(response => {
                if (response.data.success) {
                    swal({
                        title: "Done!",
                        text: "Success",
                        icon: "success",
                        timer: 1000,
                        button: false,
                        
                      })
                   
                }
                window.location='/allproduct';
            })
    }
      updateFiles = (newImages) => {
        this.setState({ images: newImages })
    }
 

      render() {

        return (
            <div>
            <div className="main-content">
                    <div className="panel-body">
                        <div className="content-area">
                        <div className="sub-bar">
                            <div className="sub-title">
                            <h4>Dashboard:</h4>
                            <span>Welcome To web Admin Panel!</span>
                            </div>
                            <ul className="bread-crumb">
                            <li><button  class="btn btn-outline-primary" >Logout</button></li> 
                            <li>Product</li>
                            </ul>
                        </div>
                        <div className="gap no-gap">
                            <div className="inner-bg">
                            <div className="element-title">
                                <h4>Edit Product</h4>
                                <span>Please fill the form bellow.</span> </div>
                            <form  onSubmit={this.onSubmit} >
                            <div className="add-prod-from">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>product name</label>
                                    <input type="text" id="producttitle" className="form-control"  name="producttitle"  onChange={this.handleChangeTitle} required/>
                                </div>
                                <div className="col-md-6">
                                <label>product category</label>
                                <input type="hidden" id="catg_hidden" />
                                    <label>product category</label>
                                    <select class="form-select" id="category"  name="selectcategory" onChange={this.handleChangeContinents}> 
                                    {/* <option selected>Open this select Category</option> */}
                                    {this.state.category.map((c) => (
                                            c.categoryname == document.getElementById('catg_hidden').value?(                                        
                                       <option selected  key={c.key} value={c.categoryname}>{ c.categoryname}</option>
                                            ):
                                        ( <option   key={c.key} value={c.categoryname}>{ c.categoryname}</option>)
                                     ))}
                                    </select> 
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                    <label>Sell price</label>
                                    <span className="input-group-addon"><i className="fa fa-gbp"/></span>
                                    <input className="form-control" name="price" id="price" type="text"  onChange={this.handleChangePrice}  required/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                    <label>discount Price</label>
                                    <span className="input-group-addon"><i className="fa fa-percent" /></span>
                                    <input className="form-control" name="discountprice" id="discountprice" type="text" onChange={this.handleChangediscountprice}  />
                                    </div>
                                </div>
        
                                <div className="col-md-6">
                                    <label>Product Description</label>
                                    <textarea rows={6} className="form-control" name="description" id="description"   placeholder="Write Description" onChange={this.handleChangeDecsription}  required />
                                </div>
                             
                                <div className="col-md-6">
                                 <label>Product Image</label>
                                 <input type="hidden" name="old_image"  id="images" />
                                 <FileUpload refreshFunction={this.updateFiles} />
                                 <label>Single image can be Upload</label>    
                                </div>
                                <div className="row">
                                    <div className="col-md-4 col-lg-4 col-sm-4">
                                    <img id="img_preview"></img>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="buttonz">
                                    <button   value="Save"  type="submit">Update</button>
                                    <button type="button">cancel</button>
                                    </div>
                                </div>
                                </div>
                             
                            </div>
                         
                            </form>                   
                    </div>
               </div>
             </div>
   </div>
</div>
</div>
        )
    }
}


export default Editproduct