import React, { Component } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import { base_url } from '../constants';

export class EditeventCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category:null
          
        };
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
      }
      handleChangeCategory(e) {
        this.setState({ categoryname: e.target.value });
    
      }
 componentDidMount= async () => {       
    let categoryId = this.props.match.params.id; 
    console.log(categoryId);
    fetch(`${base_url}/eventcategory/`+ categoryId)
    .then(response => response.json())
    .then(jsonResponse => {
           console.log(jsonResponse);
                this.setState({
                    category: jsonResponse
                })
                console.log(jsonResponse);
                //   alert(this.state.category.categoryname );
                document.getElementById('categoryname').value = this.state.category.categoryname ; 
          
    })
    .catch(error => {
        console.log(error);
    });
  }
  onSubmit = (event) => {
    event.preventDefault();
    const categories = {
        categoryname : this.state.categoryname,
  
      }
    console.log(categories);
    let categoryId = this.props.match.params.id; 
    axios.post(`${base_url}/eventcategory/update/` + categoryId, categories)
        .then(response => {
            if (response.data.success) {
                swal({
                    title: "Done!",
                    text: "Success",
                    icon: "success",
                    timer: 1000,
                    button: false,
                    
                  })
               
            }
            window.location='/alleventcategory';
        })
}
    render() {
        return (
            <div>
            <div className="main-content">
            <div className="panel-body">
                <div className="content-area">
                <div className="sub-bar">
                    <div className="sub-title">
                    <h4>Dashboard:</h4>
                    <span>Welcome To web Admin Panel!</span>
                    </div>
                    <ul className="bread-crumb">
                    <li><button  class="btn btn-outline-primary" onClick={this.logout}>Logout</button></li> 
                    <li>Category</li>
                    </ul>
                </div>
                <div className="gap no-gap">
                    <div className="inner-bg">
                    <div className="element-title">
                        <h4>Edit Category</h4>
                        <span>Please fill the form bellow.</span> </div>
                    <form  onSubmit={this.onSubmit} >
                    <div className="add-prod-from">
                    <div className="row">
                        <div className="col-md-6">
                            <label>Category Name</label>
                            <input type="text" id="categoryname" className="form-control"  name="categoryname"   onChange={this.handleChangeCategory} required/>
                        </div>
                        <div className="col-md-12">
                            <div className="buttonz">
                            <button value="submit"  type="submit">Update</button>
                            <button type="button">cancel</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </form>            
               </div>
           </div>
          </div>
          </div>
         </div>
       </div>
        )
    }
}

export default EditeventCategory
